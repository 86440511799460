import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/system';
import { Box, Button, Divider, Grid, List, ListItem, Stack, Tabs, Tab, Typography, TextField } from '@mui/material';
import { Supplier } from '../components';
import emailjs from '@emailjs/browser';
import { useSnackbar } from 'notistack';

export default function Home() {
    const { t } = useTranslation();
    const form = useRef();
    const { pathname } = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [message, setMessage] = useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const sendEmailFeedback = (e) => {
        try {
            e.preventDefault();
            emailjs.sendForm('service_p7pnynz', 'template_e50jwsj', form.current, 't97-0P3YmbvjCxYmt')
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setMessage("");
            enqueueSnackbar("Feedback sent successfully", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Failed to send feedback", { variant: "error" });
        }
    };

    const sendEmailForBusiness = async (e) => {
        try {
            e.preventDefault();
            await emailjs.sendForm('service_p7pnynz', 'template_25iclnd', form.current, 't97-0P3YmbvjCxYmt')
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setCompanyName("");
            setMessage("");
            enqueueSnackbar("Sent successfully", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Failed to send", { variant: "error" });
        }
    };

    return (
        <Box>
            <Container maxWidth="xl">
                <Box py={12.5}>
                    <Grid
                        container
                        justifyContent="center"
                    >
                        <Grid
                            xs={12}
                            md={pathname === '/en' ? 6 : 8}
                            item
                        >
                            <Typography
                                variant="h2"
                                component="h1"
                                textAlign="center"
                                color="text.primary"
                                fontSize={{
                                    xs: 34,
                                    md: 48,
                                    lg: 60
                                }}
                                sx={{
                                    marginBottom: 5
                                }}
                            >
                                {t("title")}
                            </Typography>
                            <Typography
                                variant="h6"
                                color="text.primary"
                                textAlign="center"
                                fontWeight={300}
                                fontSize={{
                                    xs: 16,
                                    md: 20
                                }}
                            >
                                {t("about")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Divider />
            <Container maxWidth="xl">
                <Box py={12.5}>
                    <Typography
                        variant="h4"
                        textAlign="center"
                        fontWeight={300}
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                        sx={{
                            marginBottom: 7
                        }}
                    >
                        {t("we")}
                    </Typography>
                    <Grid
                        justifyContent="center"
                        container
                        spacing={3}
                    >
                        <Grid
                            xs={12}
                            sm={8}
                            md={4}
                            item
                        >
                            <Typography
                                variant="h6"
                                textAlign="center"
                                fontSize={{
                                    xs: 16,
                                    md: 20
                                }}
                                sx={{
                                    marginBottom: 2
                                }}
                            >
                                {t("market developer")}
                            </Typography>
                            <Typography
                                variant="body1"
                                textAlign="center"
                                fontSize={{
                                    xs: 14,
                                    md: 16
                                }}
                            >
                                {t("market developer text")}
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={8}
                            md={4}
                            item
                        >
                            <Typography
                                variant="h6"
                                textAlign="center"
                                fontSize={{
                                    xs: 16,
                                    md: 20
                                }}
                                sx={{
                                    marginBottom: 2
                                }}
                            >
                                {t("channel expert")}
                            </Typography>
                            <Typography
                                variant="body1"
                                textAlign="center"
                                fontSize={{
                                    xs: 14,
                                    md: 16
                                }}
                            >
                                {t("channel expert text")}
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={8}
                            md={4}
                            item
                        >
                            <Typography
                                variant="h6"
                                textAlign="center"
                                fontSize={{
                                    xs: 16,
                                    md: 20
                                }}
                                sx={{
                                    marginBottom: 2
                                }}
                            >
                                {t("category based")}
                            </Typography>
                            <Typography
                                variant="body1"
                                textAlign="center"
                                fontSize={{
                                    xs: 14,
                                    md: 16
                                }}
                            >
                                {t("category based text")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Box
                py={12.5}
                backgroundColor="background.main"
            >
                <Container maxWidth="xl">
                    <Grid
                        spacing={5}
                        container
                    >
                        <Grid
                            xs={12}
                            md={4}
                            item
                        >
                            <Typography
                                variant="h4"
                                fontSize={{
                                    xs: 24,
                                    md: 34
                                }}
                            >
                                {t("solution")}
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            md={8}
                            item
                        >
                            <Grid
                                spacing={6}
                                container
                            >
                                <Grid
                                    xs={12}
                                    sm={6}
                                    item
                                >
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        fontSize={{
                                            xs: 16,
                                            md: 20
                                        }}
                                        sx={{
                                            marginBottom: 2
                                        }}
                                    >
                                        {t("market study")}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        fontSize={{
                                            xs: 14,
                                            md: 16
                                        }}
                                    >
                                        <List>
                                            <ListItem disablePadding>
                                                {t("market study text 1")}
                                            </ListItem>
                                            <ListItem disablePadding>
                                                {t("market study text 2")}
                                            </ListItem>
                                            <ListItem disablePadding>
                                                {t("market study text 3")}
                                            </ListItem>
                                        </List>
                                    </Typography>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={6}
                                    item
                                >
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        fontSize={{
                                            xs: 16,
                                            md: 20
                                        }}
                                        sx={{
                                            marginBottom: 2
                                        }}
                                    >
                                        {t("strategy")}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        fontSize={{
                                            xs: 14,
                                            md: 16
                                        }}
                                    >
                                        <List>
                                            <ListItem disablePadding>
                                                {t("strategy text 1")}
                                            </ListItem>
                                            <ListItem disablePadding>
                                                {t("strategy text 2")}
                                            </ListItem>
                                            <ListItem disablePadding>
                                                {t("strategy text 3")}
                                            </ListItem>
                                        </List>
                                    </Typography>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={6}
                                    item
                                >
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        fontSize={{
                                            xs: 16,
                                            md: 20
                                        }}
                                        sx={{
                                            marginBottom: 2
                                        }}
                                    >
                                        {t("product development")}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        fontSize={{
                                            xs: 14,
                                            md: 16
                                        }}
                                    >
                                        <List>
                                            <ListItem disablePadding>
                                                {t("product development text 1")}
                                            </ListItem>
                                            <ListItem disablePadding>
                                                {t("product development text 2")}
                                            </ListItem>
                                            <ListItem disablePadding>
                                                {t("product development text 3")}
                                            </ListItem>
                                            <ListItem disablePadding>
                                                {t("product development text 4")}
                                            </ListItem>
                                        </List>
                                    </Typography>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={6}
                                    item
                                >
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        fontSize={{
                                            xs: 16,
                                            md: 20
                                        }}
                                        sx={{
                                            marginBottom: 2
                                        }}
                                    >
                                        {t("user experience")}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        fontSize={{
                                            xs: 14,
                                            md: 16
                                        }}
                                    >
                                        <List>
                                            <ListItem disablePadding>
                                                {t("user experience text 1")}
                                            </ListItem>
                                            <ListItem disablePadding>
                                                {t("user experience text 2")}
                                            </ListItem>
                                            <ListItem disablePadding>
                                                {t("user experience text 3")}
                                            </ListItem>
                                            <ListItem disablePadding>
                                                {t("user experience text 4")}
                                            </ListItem>
                                        </List>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Container maxWidth="xl">
                <Box py={12.5}>
                    <Grid container>
                        <Grid
                            xs={12}
                            sm={10}
                            md={8}
                            lg={6}
                            item
                        >
                            <Typography
                                variant="h4"
                                fontSize={{
                                    xs: 24,
                                    md: 34
                                }}
                                sx={{
                                    marginBottom: 2
                                }}
                            >
                                {t("partners")}
                            </Typography>
                            <Typography
                                variant="body1"
                                fontSize={{
                                    xs: 14,
                                    md: 16
                                }}
                            >
                                {t("partners text")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box my={6}>
                        <Divider />
                    </Box>
                    <Grid
                        container
                        spacing={5}
                    >
                        <Grid
                            xs={12}
                            md={5}
                            item
                        >
                            <Typography
                                variant="h6"
                                fontSize={{
                                    xs: 16,
                                    md: 20
                                }}
                                sx={{
                                    marginBottom: 0.5
                                }}
                            >
                                {t("international manufacturers")}
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            md={7}
                            item
                        >
                            <Stack spacing={2}>
                                <Supplier
                                    name="Gotte"
                                    logo="/images/gottefoods.png"
                                    description={t('gotte text')}
                                    link="https://www.facebook.com/gottefoods.mn"
                                />
                                <Supplier
                                    name="Lotte"
                                    logo="/images/Lotte.png"
                                    description={t('lotte text')}
                                    link="https://www.lotte.co.kr/"
                                />
                                <Supplier
                                    name="CJ CheilJedang"
                                    logo="/images/CJ_Group.png"
                                    description={t('cj text')}
                                    link="https://www.cj.co.kr/"
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Box my={6}>
                        <Divider />
                    </Box>
                    <Grid
                        container
                        spacing={5}
                    >
                        <Grid
                            xs={12}
                            md={5}
                            item
                        >
                            <Typography
                                variant="h6"
                                fontSize={{
                                    xs: 16,
                                    md: 20
                                }}
                                sx={{
                                    marginBottom: 0.5
                                }}
                            >
                                {t("local manufacturers")}
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            md={7}
                            item
                        >
                            <Stack spacing={2}>
                                <Supplier
                                    name="Greenlips"
                                    logo="/images/greenlips.png"
                                    description={t('greenlips text')}
                                    link="https://www.facebook.com/Greenlips1"
                                />
                                <Supplier
                                    name={t("tsk")}
                                    logo="/images/TSK.webp"
                                    description={t('tsk text')}
                                    link="https://tsk-winery.com/"
                                />
                                <Supplier
                                    name={t('makhimpex')}
                                    logo="/images/maximpex.png"
                                    description={t('makhimpex text')}
                                    link="https://www.makhimpex.mn"
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Divider />
            <Container maxWidth="xl">
                <Box my={12.5}>
                    <Typography
                        variant="h4"
                        textAlign="center"
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                        sx={{
                            marginBottom: 6
                        }}
                    >
                        {t("customers")}
                    </Typography>
                    <Grid
                        spacing={2}
                        justifyContent="center"
                        container
                    >
                        <Grid
                            xs={12}
                            md={4}
                            textAlign="center"
                            item
                        >
                            <Typography
                                variant="h6"
                                fontSize={{
                                    xs: 16,
                                    md: 20
                                }}
                            >
                                {t("supermarket")}
                            </Typography>
                            <Grid
                                justifyContent="center"
                                spacing={4}
                                sx={{
                                    marginTop: '4px'
                                }}
                                container
                            >
                                <Grid item>
                                    <Box
                                        component="img"
                                        src="images/emart.png"
                                        alt="emart logo"
                                        sx={{
                                            width: "auto",
                                            height: {
                                                xs: "16px",
                                                sm: "24px",
                                                md: "32px",
                                                lg: "40px",
                                                xl: "48px"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Box
                                        component="img"
                                        src="/images/nomin1.png"
                                        alt="nomin supermarket logo"
                                        sx={{
                                            width: "auto",
                                            height: {
                                                xs: "16px",
                                                sm: "24px",
                                                md: "32px",
                                                lg: "40px",
                                                xl: "48px"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Box
                                        component="img"
                                        src="/images/sansar-supermarket.png"
                                        alt="sansar supermarket logo"
                                        sx={{
                                            width: "auto",
                                            height: {
                                                xs: "16px",
                                                sm: "24px",
                                                md: "32px",
                                                lg: "40px",
                                                xl: "48px"
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            xs={12}
                            md={4}
                            textAlign="center"
                            item
                        >
                            <Typography
                                variant="h6"
                                fontSize={{
                                    xs: 16,
                                    md: 20
                                }}
                            >
                                {t("convenience")}
                            </Typography>
                            <Grid
                                justifyContent="center"
                                spacing={4}
                                sx={{
                                    marginTop: '4px'
                                }}
                                container
                            >
                                <Grid item>
                                    <Box
                                        component="img"
                                        src="images/CU.png"
                                        alt="CU logo"
                                        sx={{
                                            width: "auto",
                                            height: {
                                                xs: "16px",
                                                sm: "24px",
                                                md: "32px",
                                                lg: "40px",
                                                xl: "48px"
                                            }
                                        }}
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <Box
                                        component="img"
                                        src="/images/gs25.png"
                                        alt="GS25 logo"
                                        sx={{
                                            width: "auto",
                                            height: {
                                                xs: "16px",
                                                sm: "24px",
                                                md: "32px",
                                                lg: "40px",
                                                xl: "48px"
                                            }
                                        }}
                                    />
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid
                            xs={12}
                            md={4}
                            textAlign="center"
                            item
                        >
                            <Typography
                                variant="h6"
                                fontSize={{
                                    xs: 16,
                                    md: 20
                                }}
                            >
                                {t("online shops")}
                            </Typography>
                            <Grid
                                justifyContent="center"
                                spacing={4}
                                sx={{
                                    marginTop: '4px'
                                }}
                                container
                            >
                                <Grid item>
                                    <Box
                                        component="img"
                                        src="images/emart-mall.png"
                                        alt="emart-mall logo"
                                        sx={{
                                            width: "auto",
                                            height: {
                                                xs: "16px",
                                                sm: "24px",
                                                md: "32px",
                                                lg: "40px",
                                                xl: "48px"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Box
                                        component="img"
                                        src="images/enomin.png"
                                        alt="enomin logo"
                                        sx={{
                                            width: "auto",
                                            height: {
                                                xs: "16px",
                                                sm: "24px",
                                                md: "32px",
                                                lg: "40px",
                                                xl: "48px"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Box
                                        component="img"
                                        src="/images/shoppy.svg"
                                        alt="shoppy logo"
                                        sx={{
                                            width: "auto",
                                            height: {
                                                xs: "16px",
                                                sm: "24px",
                                                md: "32px",
                                                lg: "40px",
                                                xl: "48px"
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Divider />
            <Container maxWidth="xl">
                <Box
                    my={12.5}
                    justifyContent="center"
                >
                    <Typography
                        variant="h4"
                        textAlign="center"
                        fontSize={{
                            xs: 24,
                            md: 34
                        }}
                        sx={{
                            marginBottom: 6
                        }}
                    >
                        {t("contact")}
                    </Typography>
                    <Grid
                        justifyContent="center"
                        container
                    >
                        <Grid
                            xs={12}
                            sm={8}
                            md={6}
                            lg={4}
                            item
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="fullWidth"
                                sx={{
                                    position: 'relative',
                                    marginBottom: 3,
                                    '&:before': {
                                        content: '" "',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 'auto',
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        height: '1px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.12)'
                                    }
                                }}
                            >
                                <Tab
                                    label={
                                        <Typography variant="subtitle1">
                                            {t("business")}
                                        </Typography>
                                    }
                                />
                                <Tab
                                    label={
                                        <Typography variant="subtitle1">
                                            {t("feedback")}
                                        </Typography>
                                    }
                                />
                            </Tabs>
                            {value === 0 && (
                                <form
                                    ref={form}
                                    onSubmit={sendEmailForBusiness}
                                >
                                    <Grid
                                        columnSpacing={1.5}
                                        container
                                    >
                                        <Grid
                                            xs={6}
                                            item
                                        >
                                            <TextField
                                                name="firstName"
                                                label={t("first name")}
                                                value={firstName}
                                                onChange={e => setFirstName(e.target.value)}
                                                type="text"
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid
                                            xs={6}
                                            item
                                        >
                                            <TextField
                                                name="lastName"
                                                label={t("last name")}
                                                value={lastName}
                                                onChange={e => setLastName(e.target.value)}
                                                type="text"
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        name="email"
                                        label={t("email")}
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        type="email"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        required
                                    />
                                    <TextField
                                        name="phone"
                                        label={t("phone number")}
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                        type="tel"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        required
                                    />
                                    <TextField
                                        name="companyName"
                                        label={t("company name")}
                                        value={companyName}
                                        onChange={e => setCompanyName(e.target.value)}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        required
                                    />
                                    <TextField
                                        name="message"
                                        label={t("message")}
                                        value={message}
                                        onChange={e => setMessage(e.target.value)}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        rows={6}
                                        multiline
                                        fullWidth
                                        required
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        fullWidth
                                        disableElevation
                                        sx={{
                                            marginTop: 1,
                                            textTransform: 'uppercase'
                                        }}
                                    >
                                        {t("send")}
                                    </Button>
                                </form>
                            )}
                            {value === 1 && (
                                <form
                                    ref={form}
                                    onSubmit={sendEmailFeedback}
                                >
                                    <Grid
                                        columnSpacing={1.5}
                                        container
                                    >
                                        <Grid
                                            xs={6}
                                            item
                                        >
                                            <TextField
                                                name="firstName"
                                                label={t("first name")}
                                                value={firstName}
                                                onChange={e => setFirstName(e.target.value)}
                                                type="text"
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid
                                            xs={6}
                                            item
                                        >
                                            <TextField
                                                name="lastName"
                                                label={t("last name")}
                                                value={lastName}
                                                onChange={e => setLastName(e.target.value)}
                                                type="text"
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        name="email"
                                        label={t("email")}
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        type="email"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        required
                                    />
                                    <TextField
                                        name="phone"
                                        label={t("phone number")}
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                        type="tel"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        required
                                    />
                                    <TextField
                                        name="message"
                                        label={t("message")}
                                        value={message}
                                        onChange={e => setMessage(e.target.value)}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        rows={6}
                                        fullWidth
                                        multiline
                                        required
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        fullWidth
                                        disableElevation
                                        sx={{
                                            marginTop: 1,
                                            textTransform: 'uppercase'
                                        }}
                                    >
                                        {t("send")}
                                    </Button>
                                </form>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

